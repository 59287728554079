@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap");

body,
* {
  margin: 0;
  font-family: "Archivo", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

h3 {
  font-weight: bold;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  transition: 0.3s all ease-in;
  border: 1px solid #5fbeaa;
}

.loader {
  display: flex;
  margin: 0 auto;
  width: 3.5em;
  height: 3.5em;
  border: 3px solid transparent;
  border-top-color: #ff5101a2;
  border-bottom-color: #ff5101a2;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

.ant-table-cell {
  font-size: 12px !important;
}

.loader:before {
  content: "";
  display: block;
  margin: auto;
  width: 0.75em;
  height: 0.75em;
  border: 3px solid #ff5001;
  border-radius: 50%;
  animation: pulse 1s alternate ease-in-out infinite;
}

.react-international-phone-input-container .react-international-phone-input {
  display: block !important;
  width: 100% !important;
  padding: 22px 10px !important;
  border: 1px solid #00000033 !important;
  /* font-size: 16px !important; */
  font-family: Montserrat !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  font-family: "Raleway", sans-serif !important;
}

.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  padding: 22px 10px !important;
  border: 1px solid #00000033 !important;
}

.react-international-phone-input-container
  .react-international-phone-country-selector-button:focus,
.react-international-phone-input-container
  .react-international-phone-input:focus {
  outline: none !important;
  border: 1px solid #5fbeaa !important;
}

.ant-menu-item-selected .ant-menu-title-content,
.ant-menu-item-selected svg {
  color: #5fbeaa !important;
}

:where(.css-dev-only-do-not-override-vryruh).ant-menu-light
  .ant-menu-item-selected,
:where(.css-dev-only-do-not-override-vryruh).ant-menu-light
  > .ant-menu
  .ant-menu-item-selected {
  background: #fff !important;
}

.ant-menu-item {
  color: #fff !important;
}

:where(.css-dev-only-do-not-override-vryruh).ant-menu .ant-menu-item {
  border-radius: 0 !important;
}

:where(.css-dev-only-do-not-override-vryruh).ant-menu-inline .ant-menu-item,
:where(.css-dev-only-do-not-override-vryruh).ant-menu-vertical .ant-menu-item,
:where(.css-dev-only-do-not-override-vryruh).ant-menu-inline
  .ant-menu-submenu-title,
:where(.css-dev-only-do-not-override-vryruh).ant-menu-vertical
  .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  padding-inline: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-inline: 0px !important;
  margin-block: 4px;
  width: 100% !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.loader {
  display: flex;
  margin: 0 auto;
  width: 3.5em;
  height: 3.5em;
  border: 3px solid transparent;
  border-top-color: #ff5101a2;
  border-bottom-color: #ff5101a2;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

.loader:before {
  content: "";
  display: block;
  margin: auto;
  width: 0.75em;
  height: 0.75em;
  border: 3px solid #ff5001;
  border-radius: 50%;
  animation: pulse 1s alternate ease-in-out infinite;
}

:where(.css-dev-only-do-not-override-vryruh).ant-otp .ant-otp-input {
  height: 60px;
  width: 60px;
}

.ant-drawer-body {
  padding: 0 !important;
}

.card {
  background: #fff;
  border-radius: 10px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
}

.heading {
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

@media screen and (max-width: 1200px) {
  /* .dash {
    display: none;
  } */
}
